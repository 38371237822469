/* FROM GLOBAL-EXT-LIB */

/* UAC chart */
var medfuac,gsll=new Array;gsll[0]=new Array("0","M"),gsll[1]=new Array("1","E"),gsll[2]=new Array("2","D"),gsll[3]=new Array("3","S"),gsll[4]=new Array("4","C"),gsll[5]=new Array("5","A"),gsll[6]=new Array("6","P"),gsll[7]=new Array("7","B"),gsll[8]=new Array("8","F"),gsll[9]=new Array("9","H");var gll=new Array;gll[0]=new Array("0","Z"),gll[1]=new Array("1","T"),gll[2]=new Array("2","Y"),gll[3]=new Array("3","V"),gll[4]=new Array("4","G"),gll[5]=new Array("5","X"),gll[6]=new Array("6","N"),gll[7]=new Array("7","R"),gll[8]=new Array("8","K"),gll[9]=new Array("9","J");

/* /FROM GLOBAL-EXT-LIB */
 
 
/* FROM MEDSCAPE-COMMON */

// Virtual Page Tracking function
var trackurl = $("link[rel='canonical']").attr("href");

function BItrack(thisURL) {
    window.VPTrackFrame.getFile("desturl=" + trackurl + "%5f" + thisURL);
}

function logoutMessage() {
    $("#layer-logout-confirmation, #layer-logout-bg").addClass("showLogout");
}
function cancelLogoutMessage() {
    $("#layer-logout-confirmation, #layer-logout-bg").removeClass("showLogout");
}

/* USED ON PRIVACY POLICY / FOOTER LINKS */
// This function works for window pop-up. Assigning below function to OpenPopup.
function resizeWin(newLoc, newHeight, newWidth) {
    newWin = open("", newLoc, "scrollbars=yes,resizable=yes,height=" + newHeight + ",width=" + newWidth);
}
var OpenPopup = resizeWin;

// if Medscape app remove onclick & target to restrict opening resizeWin in app
if (typeof isMscpApp !== 'undefined' && isMscpApp) {
	$('a[target=Figure]').removeAttr('onclick').removeAttr('target');
}

var theTop = 70;
var old = theTop;
function movelayers(num) {
    if (window.innerHeight) {
        pos = window.pageYOffset;
    } else if (document.documentElement && document.documentElement.scrollTop) {
        pos = document.documentElement.scrollTop;
    } else if (document.body) {
        pos = document.body.scrollTop;
    }

    (pos < theTop) ? pos = theTop : pos += 50;

    if (pos == old) {
        document.getElementById(num).style.top = (pos + 'px');

    } else {
        old = pos;
        movelayers(num);
    }
}

/* begin selective driver code */
var selectivedriver = {
    curtime: 0,          // the current countdown second
    countdowntime: 5,    // the number seconds to count down to
    loadtime: 1000,      // the number of seconds to load the layer before scrolling
    countdowntimerid: -1,// stores the running interval of the count down timer
    link:"",             // the link the selectivedriver goes to

    // cached jquery elements
    $body: null,
    $window: null,
    $counter: null,
    $pxcntnum: null,

    init: function() {
        var self = this;
        var isMobile = typeof window.orientation !== 'undefined' || /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        if (isMobile) {
            var renderPos = 'ads-pos-1005';
        } else {
            var renderPos = 'ads-pos-910';
        }
        var $adslot = $('#' + renderPos);
        // check if mobile and 1005 ad is on the page
        if($adslot.length == 0)
            return;

        // removed check for render now that it's being fired from the ad itself
        // wait until the iframe loads before loading the layer content
        $adslot.find("iframe").load(function () {
            var $sdlayerhtml = $('#' + renderPos + ' iframe').contents().find("body .sd-layer-background");
            if ($sdlayerhtml.length == 0)
                return;
            $sdlayerhtml = $sdlayerhtml.clone().wrap("<div>");
            $adslot.after($sdlayerhtml.parent().html());
            self.domReady();
        });
    },

    domReady: function() {
        // check if a selective driver is on the page
        var $sdlayer = $(".selective-driver");
        var self = this;
        self.$body = $("body");
        self.$window = $(window);

        if($sdlayer.length <= 0)
            return;

        $sdlayer.find(".close a").click(function(e){
            self.closeLangMenu();
            e.preventDefault();
        });

        $sdlayer.find(".content").click(function(e){
            var url = $(this).find(".btn-link").attr("href");
            self.gotoURL(url);
            e.preventDefault();
        });
        self.openLangMenu();
    },

    gotoURL: function(url) {
        var self = this;
        clearInterval(self.countdowntimerid);
        var delaytimerid = setTimeout(function() {
            if(url != "") {
                // check if a query string is present
                if(url.indexOf('?') > -1)
                    url += "&seldriver=true";
                else url += "?seldriver=true";
                window.location = url;
            }
            clearTimeout(delaytimerid);
            self.closeLangMenu();
        }, 250);
    },

    openLangMenu: function() {
        var self = this;
        if(this.$body.hasClass("sd-layer-show") == true)
            return;
        this.$body.addClass("sd-layer-show");

        $("body").on("touchmove.prevent-scroll", function (e) {
            e.preventDefault();
        }).on("touchmove.enable-scroll", ".content-wrap", function (e) {
            e.stopPropagation();
        });
        // check if selective driver is count down to play
        if($(".selective-driver-cdtp").length <= 0)
            return;
        self.$pxcntnum = $(".selective-driver-cdtp #pxcntnum");
        self.$counter = $(".selective-driver-cdtp #counter");
        self.link = $(".selective-driver-cdtp .btn-link").attr("href");
        this.$counter.html(self.countdowntime - self.curtime);
        self.countdowntimerid = setInterval(function () {
            self.curtime++;
            self.$pxcntnum.attr("value", self.curtime);
            self.$counter.html(self.countdowntime - self.curtime);
            if(self.countdowntime - self.curtime == 0) {
                clearInterval(self.countdowntimerid);
                self.gotoURL(self.link);
                return;
            }
        }, 900);
    },

    closeLangMenu : function() {
        var self = this;
        $("body").off("touchmove.prevent-scroll");
        this.$body.removeClass("sd-layer-show");
        clearInterval(self.countdowntimerid);
    }
};

function getHeliosDomain(dataEnv) {
	var domain = '';
    if (document.domain.indexOf('.dev') != -1) {
        domain = 'devint.';
    } else if (document.domain.indexOf('.qa') != -1) {
        domain = 'qa.';
    } else if (document.domain.indexOf('.staging') != -1) {
        domain = 'staging.';
    } else if (dataEnv) {
		domain = 'production.';
	}
	if (dataEnv) {
		domain = domain.split('.')[0];
	}	
	return domain;
}

/* FPF */
function appendFPFLayer() {
	var header = '';
	var teaser = '';
	var buttonLabel = '';
	var lang = locale;
	lang == 'us' ? lang = 'en' : null;
	switch (lang) {
		case 'us':
			header = 'Log in or register for free to unlock more Medscape content';
			teaser = 'Unlimited access to our entire network of sites and services';
			buttonLabel = 'Log in or Register';
			break;
		case 'de':
			header = 'Melden Sie sich an oder registrieren Sie sich kostenlos, um mehr Medscape-Inhalte freizuschalten';
			teaser = 'Unbegrenzter Zugang zu unserem gesamten Netzwerk von Webseiten und Diensten';
			buttonLabel = 'Anmelden oder Registrieren';
			break;
		case 'es':
			header = 'Inicie sesión o regístrese gratis para acceder a más contenido de Medscape';
			teaser = 'Acceso ilimitado a toda la red y servicios de Medscape';
			buttonLabel = 'Inicie sesión o Regístrese';
			break;
		case 'fr':
			header = 'Connectez-vous ou inscrivez-vous gratuitement pour accéder à davantage de contenu Medscape.';
			teaser = 'Accès illimité à l\'ensemble de notre réseau de sites et de services';
			buttonLabel = 'Se connecter ou S\'inscrire';
			break;
		case 'pt':
			header = 'Faça login ou registre-se gratuitamente para desbloquear mais conteúdos do Medscape';
			teaser = 'Acesso ilimitado a toda nossa rede de sites e serviços';
			buttonLabel = 'Faça login ou registre-se';
			break;
		default:
			header = 'Log in or register for free to unlock more Medscape content';
			teaser = 'Unlimited access to our entire network of sites and services';
			buttonLabel = 'Log in or Register';
			break;
	}

	var fpfHtml = '<span class="faf-border"></span> <span class="faf-toggle"></span>' +
		'<div class="faf-content">' +
		'<div class="logo-mscp">Medscape</div>' +
		'<div class="faf-copy">' +
		'<p class="callout"> ' + header + '</p>' +
		'<p class="description">' + teaser + '</p>' +
		'</div>' +
		'<div class="faf-cta">' +
		'<a href="javascript:void(0);" class="faf-button">' + buttonLabel + '</a>' +
		'</div>' +
		'</div>' +
		'<div id="fpfWidget" data-lang="' + lang + '" data-env="' + getHeliosDomain(true) + '">' +
		'</div>'

	var fpfDiv = document.createElement('div');
	fpfDiv.className = 'faf';
	fpfDiv.innerHTML = fpfHtml;
	var layerDiv = document.createElement('div');
	layerDiv.className = 'layer-greyout';
	document.getElementById('bodypadding').appendChild(fpfDiv);
	document.getElementById('bodypadding').appendChild(layerDiv);
    document.dispatchEvent(new CustomEvent('fpfAppended'));
}

function toggleFPF(event) {
	if (document.querySelector('.faf.active') != null) {
		document.querySelector('.faf').classList.remove('active');
		document.querySelector('.layer-greyout').classList.remove('active');
		document.querySelector('body').classList.remove('faf-enabled');
		wmdPageLink('reg-login-reboot-faf_minimize', this);
	} else {
		document.querySelector('.faf').classList.add('active');
		document.querySelector('.layer-greyout').classList.add('active');
		document.querySelector('body').classList.add('faf-enabled');
		if (document.querySelector('#bodypadding').getBoundingClientRect().top + document.querySelector('#bodypadding').getBoundingClientRect().height - window.innerHeight < 0) { // if FAF scrolled past 'sticky' bottom, detected by parent container
			document.querySelector('.faf').scrollIntoView(false);
		}
		if (typeof event == 'undefined') { // if called by JS on second visit, remove toggle option
			document.querySelector('.faf-toggle').remove();
		} else { // else expand was clicked
			wmdPageLink('reg-login-reboot-faf_expand', this);
		}
	}
}

function loadFPFLoginJS() {
	if (document.querySelector('script[src*="page-fpf"]') != null) {
		return;
	}
	var head = document.getElementsByTagName('head')[0];
	var style = document.createElement('link');
	style.href = 'https://img.' + getHeliosDomain() + 'wbmdstatic.com/medscape_static_vue/page-fpf/client/main.es6.css';
	style.type = 'text/css';
	style.rel = 'stylesheet';
	head.append(style);
	var script = document.createElement('script');
	script.src = 'https://img.' + getHeliosDomain() + 'wbmdstatic.com/medscape_static_vue/page-fpf/client/main.es6.js';
	script.type = 'text/javascript';
	script.onload = function() { document.dispatchEvent(new CustomEvent('fpfAppended')); };																					
	head.append(script);
}

function initFPFWidget() {
	if (fpfMeta['isFPF'] || fpfMeta['isFAFAnon']) {
		loadFPFLoginJS(); // Load FPF standalone JS/CSS modules
		
		// Append HTML and addEventListener for Continue / Toggle buttons
		appendFPFLayer();
		document.querySelector('.faf .faf-button').addEventListener('click', toggleFPF);
		document.querySelector('.faf .faf-toggle').addEventListener('click', toggleFPF);
		
        if (fpfMeta['isFPF']) { // Auto-open layer if not the first visit
            toggleFPF();
        } else if (!fpfMeta['fafRef']) { // If first/free visit, increment cookie count
            expiry = new Date();
            expiry.setTime(expiry.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30days
            document.cookie = 'first_article_visited= ' + (fpfMeta['fafSeen'] + 1) + '; path=/; domain=medscape.com; expires=' + expiry.toGMTString();
        }
	}
}
/* FPF */

if (typeof addBrandAlert == 'undefined') { // Non-responsive pages should have this function defined via legacy global JS files, 'global-desktop.js' / 'global-mobile.js'

function postMessageHandlerGlobal(event) {
	if (event.origin.match(/(medscape|googlesyndication)/) !== null) {
		try {
			var data = JSON.parse(event.data);
			if (data.callBack && typeof window[data.callBack] === 'function') {
				window[data.callBack](data, event.source);
			}
		} catch (e) {}
	}
}

window.addEventListener('message', postMessageHandlerGlobal.bind(this));


}

function postData(data,i){

    if (data[i].activityId != "") {
        cpEventTrack = '<script type="text/javascript">cpHlPush({"tcid": "' + data[i].tcid + '", "activityId": "' + data[i].activityId + '"});</script>';
    } else {
        cpEventTrack = '';
    }
    if (data[i].hasOwnProperty("tiarray")) {
        fHeadti = data[i].tiarray[Math.round(Math.random() * (data[i].tiarray.length - 1))]
    } else {
        fHeadti = data[i].ti;
    }
    if (data[i].hasOwnProperty("pub")) {
        if (data[i]["pub"] !== "") {
            fPubLine = data[i]["pub"]
        } else {
            fPubLine = data[i].pu
        }
    } else {
        fPubLine = data[i].pu
    }
    if (data[i].uri.indexOf('?') !== -1) {
        queryamp = "&";
    } else {
        queryamp = "?";
    }
    if (data[i].dom.indexOf('wp') !== -1) {
        if (data[i].dom.indexOf('staging') !== -1) {
            vpDom = "www.staging.medscape.com";
        } else {
            vpDom = "www.medscape.com";
        }
    } else {
        vpDom = data[i].dom;
    }

    bavpurl = data[i].uri.replace("browser", "headline");
}

/* Layer and Src iframe loader */
function loadidetailiframe(idetailurl, detailiframewidth, detailiframeheight, detailiframetop, detailiframeleft) {

    var idetailwinbgwidth = (detailiframewidth * 1) + 24;
    var idetailwinbgheight = (detailiframeheight * 1) + 48;
    var framecover = document.getElementById("idetailiframewrapper");
    var frame = parent.document.getElementById("idetailiframe");
    var winbg = parent.document.getElementById("idetailwinbg");
    var my_div2 = parent.document.getElementById("bodypadding");
    var cssStyle;

    var newDiv2 = parent.document.createElement("div");
    newDiv2.setAttribute("id", "whiteoutlayer");
    newDiv2.innerHTML = " ";

    parent.document.body.insertBefore(newDiv2, my_div2);
    setElementClass(framecover, 'active');

    parent.document.location = "#";

    frame.style.cssText = 'width:'+ detailiframewidth +'px;height:'+detailiframeheight+'px;';
    frame.src = idetailurl;

    cssStyle = 'position:absolute;zIndex:2000;top:'+detailiframetop+';left:'+detailiframeleft+
        ';width:'+idetailwinbgwidth + 'px;height:'+idetailwinbgheight + 'px;';
    winbg.style.cssText = cssStyle;
}


function removeidetailiframe() {
    if ((window.pixnumtrack) && (pixnumtrack <= 6)) {
        var oIframe = document.getElementById("idetailiframe");
        var oDoc = oIframe.contentWindow || oIframe.contentDocument;
        if (oDoc.document) {
            oDoc = oDoc.document;
        }
        var timespentpxval = oDoc.getElementById("pxcntnum").value;
        var rornval = oDoc.getElementById("rorn").value;
        var cnttrack = "//bi.medscape.com/pi/1x1/bi_action_tracking.gif?company=0&department=0&series=0&promo_act=0&action=" + rornval + "_CLOSE&action_id=" + timespentpxval + "&ccid=&eguid=&is_test=0&" + new Date().getTime();
        preload_image = new Image(1, 1);
        preload_image.src = cnttrack;
    }
    if ($('#rollover_ad').length > 0) {
        setTimeout("adRollTime=0", 500);
        var rotrack = "//bi.medscape.com/pi/1x1/bi_action_tracking.gif?company=0&department=0&series=0&promo_act=0&action=ro-to-play&action_id=ro-close&ccid=&eguid=&is_test=&nocache=" + new Date().getTime();
        preload_image = new Image(1, 1);
        preload_image.src = rotrack;
    }
    parent.document.getElementById("idetailiframe").src = "//www.medscape.com/files/public/blank.html";
   // var framecover = document.getElementById("idetailiframewrapper");
    //setElementClass(framecover, 'inactive');
    $("#idetailiframewrapper").attr('class','inactive');
    if (document.getElementById("whiteoutlayer")) {
        var element = document.getElementById("whiteoutlayer");
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
        element.id = "";
    }
}

/* MODIFIED GLOBAL FUNCTION FOR LEGACY SUPPORT */
function setElementClass(element, classValue) {
	element.className = classValue;
}

/* Remove OSUN Notice */
function removeosun(state) {
    if (document.getElementById) {
        var osunid = document.getElementById("medlayerad");
        setElementClass(osunid, state);
    }
}

/* NEW Remove OSUN Notice (generic to all new layer ads) */
function removeosun2(state, layid) {
    $("#"+layid).addClass(state);
}

/* DOC READY BLOCK - ADDING CHECK FOR RESPONSIVE PAGES, AS IT MOVED FROM MEDSCAPE-COMMON INTO MEDSCAPE-LIBRARY, MEANING NOW ON LEGACY PAGES AS WELL */
if (typeof s_responsive_design != 'undefined' && s_responsive_design) {
	$(document).ready(function() {
		
		if ( breakpoint === 1){

			if (navigator.userAgent.toLowerCase().indexOf("android") != "-1") {
				$("body").addClass('isAndroid');
			}


		} else {


			//Adding the slideshow byline
			$('#imgCollections .byline').html('Slideshow');

			//add tracking to slideshow list items on reference homepage
			if ($('.medscape_ref').length != 0 && $('.homestar').length != 0) {
				$('#imgCollections .bucketContent ul li').each(function(index) {
					$(this).find('a').attr("onclick","wmdTrack('slide_" + (index+1) + "')");
				});

				$('#featured .bucketContent ul.listLeft li').each(function(index) {
					if (!$(this).hasClass('brandAlert')) {
						$(this).find('a').attr("onclick","wmdTrack('feat_" + (index+1) + "')");
					}
				});
				$('#featured .bucketContent ul.listRight li').each(function(index) {
					if (!$(this).hasClass('brandAlert')) {
						$(this).find('a').attr("onclick","wmdTrack('feat_" + (index+3) + "')");
					}
				});
			}

			$('.whiteout-header-dropdown, .header-specialty-close-button').bind('click', function() {
				if($("body").hasClass("dropdown-view")) {
					$("body").removeClass("dropdown-view");
					$('.header-specialty-menu').removeClass('is-expanded');
					$('.header-specialty-toggle').find('.sp-arrow').removeClass('is-rotated');
				} else {
					$("body").addClass("dropdown-view")
				}
			});
			$('.header-specialty-toggle').bind('click', function() {
				if($("body").hasClass("dropdown-view")) {
					$("body").removeClass("dropdown-view");
				} else {
					$("body").addClass("dropdown-view")
				}
			});

			$('#currentSpecialty, #headertopright .btn-close').bind('click', function() {
				if($("body").hasClass("dropdown-view")) {
					$("body").removeClass("dropdown-view")
				} else {
					$("body").addClass("dropdown-view")
				}
			});

			
			/* make the whole area of div#layer2 clickable */
			$("body").on('click', "#layerAd2 .adContent", function(e) {
				window.open($(this).find(".adTitle a").attr("href"));
			});

			/* avoid propagating the event to prevent div#layer2 click event from being called */
			$("body").on('click', "#layerAd2 .adContent a", function(e) {
				e.stopPropagation();
			});

			// PPE-43892 - Fix All text layer ads broken in iPad/Safari browser view
			var ua = navigator.userAgent.toLowerCase();
			var check = function(r) { return r.test(ua); };
			var isChrome = check(/chrome/);
			var isSafari = !isChrome && check(/safari/);
			if(isSafari) {
				var interval = setInterval(function(){
					if($("#layerAd2").length > 0) {
						var AdNode = document.getElementById("layerAd2").cloneNode(true);
						$("#layerAd2").remove();
						window.document.body.appendChild(AdNode);
						clearInterval(interval);
					} // end if
				}, 500);

				// stop checking if the parent window is loaded
				$(window).load(function(){
					clearInterval(interval);
				});
			}

		}
		
		$('.byline span').filter(function() { return $(this).parents('.nativecallouts-item').length === 0; }).removeClass('lastVisible');
		$('.byline').find('span:not(.viafoura-commentcount,.livefyre-commentcount):visible:last').addClass('lastVisible');		
		//viafoura comment count code
		vfCommentCount();
		
		// PPE-388963 - block FPF for PulsePoint
		if (typeof window.libLoadStatus == 'object' && window.libLoadStatus.complete) {
			initFPFWidget();
		} else if (typeof window.libLoadStatus == 'object') {
			document.addEventListener('userIDComplete', initFPFWidget);
		} else {
			initFPFWidget();	
		}

	});
	
	
	
}
/* DOC READY BLOCK */
/* THIS LOOKS LIKE LEGACY CODE, NO LONGER USED ON PUBLICATION PAGES */
/* function viewPublish(element, publishlink) {
    if (element) {
        var vp_a = $(publishlink).attr('href');
        var vp_txt = $(publishlink).html();
        $("#journalnav ul:nth-child(2)").append('<li><a href="'+vp_a+'">'+vp_txt+'</a></li>');
    }
}
// PORTION BELOW FROM DOC-READY
			viewPublish(document.getElementById("viewpub_publisher"), '#pubintro a#viewpub_publisher');
			viewPublish(document.getElementById("viewpub_subscribe"), '#pubintro a#viewpub_subscribe');
			viewPublish(document.getElementById("viewpub_submit"), '#pubintro a#viewpub_submit'); */

			
/* ESCAPE KEY LOGIC - FIND OUT IF IT STILL WORKS ANYWHERE - SEE SOME CALLS ON EDUCATION edu-article ETC - I THINK BROKEN, COMMENTING OUT FOR NOW */
/* $(window).keydown(function(e) {
    if (e.keyCode == 27) {

        var layer=$('body').find('.active').attr('id');
        if(typeof layer === 'undefined'){
            layer =  $('body').find('.feed_Overlay').attr('id');
            if(layer === 'feed_Overlay'){

            }
            return;
        }
        if(layer === 'author_layer'){

            pop_layer(layer);
            return;
        }
        if(layer === 'emailadexcontainer'){
            showemailtoolbox('inactive');
        }
        if(layer === 'clinref_tablelayer'){
            reftablehide();
        } else {
            hiderefcontent(layer);
        }

    }
}); */

/* ONLY USED ON DEFUNCT 'home-infosites.jsp' FRAGMENTS */
/* Random List of li */
/* function showuniquelist(thelist, numselect) {
    var listitems = new Array();
    listitems = document.getElementById(thelist).getElementsByTagName("li");
    var totallist = listitems.length;
    var numofitems = listitems.length - 1;
    if (totallist <= numselect) {
    } else {
        var Count = 0;
        var NumUnique = numselect;
        var Current = new Array(NumUnique);
        for (; Count < NumUnique; Count++) {
            var Found = false;
            var rndValue = get_random(numofitems);
            for (j = 0; j < Current.length; j++) {
                if (Current[j] == rndValue) {
                    Found = true;
                    break;
                }
            }
            if (Found) {
                Count--;
            } else {
                Current[Count] = rndValue;
            }
        }
        for (i = 0; i < listitems.length; i++) {
            listitems[i].style.display = "none";
        }

        for (i = 0; i < 6 ; i++) {
            listitems[yi].style.display = "block";
        }
    }
}

function get_random(numofitems) {
    return (Math.round(numofitems*Math.random()));
} */

/* I DON'T SEE THIS ONE BEING CALLED ANYWHERE? COMMENTING OUT FOR NOW */
/* NEW Random List function allows for any child elements in a parent to be randomized and limited */
/* $.fn.randomli = function(listEl,numDis){
    var $allChild = listEl ? $(this).find(listEl) : $(this).children(),
        $theParent = $allChild.parent();
    $theParent.each(function(){
        $(this).children(listEl).sort(function(){
            return Math.round(Math.random()) - 0.5;
        }).detach().appendTo(this);
    });
    $(this).find($(this).children()).slice( numDis ).detach();
    return this;
}; */

/* IT SEEMS LIKE THIS ONE ONLY CALLED BY SOME LEGACY MOBILE HEADER??? FOUND IN STASH SEARCH HERE: https://stash.portal.webmd.com/projects/PE/repos/prof_jdk/browse/scripts/RTMS_VMCM_PERF_ViewMedscapeCollectionMobile/result1/Iteration1/t1.html#60 */
/* function showSp(value) {
    if (value === "specialty") {
        $("ul#specialtyDD_list").toggle();
        $("#specialty-link .sp_arrow").toggleClass("sp_downarrow");
    }
    else if (value === "edition") {
        $("ul#edition-list").toggle();
        $("#edition-link .sp_arrow").toggleClass("sp_downarrow");
    }
} */

/* BELOW FUNCTION FOR SOME LEGACY REF TYPEAHEAD INPUT BELIEVE */
/*
// Clear search input box for mobile device
function clearSearch() {
    $('#searchtextinput').val('').focus();
}
*/

/* FROM MEDSCAPE-COMMON */


/* FROM MEDSCAPE-LIBRARY */

function pixTrack(url) {
    var preload_image = new Image(1, 1);
    preload_image.src = url;
}

//
// Dynamic 'More' Link Function
//
function showMoreLinks(divWrap, minLength, amountShown) {
    $(document).ready(function () {
        $(divWrap + ' ul').each(function () {
            if ($(this).find('li').length >= minLength)
            {
                $(this).find('li:gt(' + amountShown + ')').hide();
                $(this).find('li:eq(' + amountShown + ')').hide();
                $(this).append('<li><div class="morelink"><img height=\"7\" border=\"0\" width=\"9\" src=\"//img.medscape.com/pi/global/icons/icon-arrowmore.gif\" alt=\"\"> <a class=\"showmore\" href="#">More</a></div></li>');
            }
        });
        $('.showmore').click(function (event) {
            event.preventDefault();
            $(this).parent().parent().parent().find('li:eq(' + amountShown + ')').show();
            $(this).parent().parent().parent().find('li:gt(' + amountShown + ')').show();
            $(this).parent().parent().remove();
        });
    });
}

/* Share link basic functions */
function fburl(elem) {
	var trackVal = 'ar-share_face';
    var shrUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?src=soc_fb_share';
    var shrTitle = document.title;	
	if (typeof elem != 'undefined' && typeof elem.getAttribute != 'undefined') {	
		if (elem.getAttribute('data-track') != null && elem.getAttribute('data-track') != '') {
			trackVal = elem.getAttribute('data-track');
		}		
		if (elem.getAttribute('data-uri') != null && elem.getAttribute('data-uri') != '') {
			shrUrl = elem.getAttribute('data-uri') + '?src=soc_fb_share';
		}		
		if (elem.getAttribute('data-title') != null && elem.getAttribute('data-title') != '') {
			shrTitle = elem.getAttribute('data-title');
		}	
	}	
    var shrLink = "//www.facebook.com/share.php?u=" + encodeURIComponent(shrUrl) + "&t=" + shrTitle;
	
    wmdPageLink(trackVal, elem);
    socialCp('Facebook');	
    window.open(shrLink);
}
function twiturl(elem) {
	var trackVal = 'ar-share_twit';
    var shrUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?src=soc_tw_share';
    var shrTitle = document.title;	
	if (typeof elem != 'undefined' && typeof elem.getAttribute != 'undefined') {	
		if (elem.getAttribute('data-track') != null && elem.getAttribute('data-track') != '') {
			trackVal = elem.getAttribute('data-track');
		}		
		if (elem.getAttribute('data-uri') != null && elem.getAttribute('data-uri') != '') {
			shrUrl = elem.getAttribute('data-uri') + '?src=soc_tw_share';
		}		
		if (elem.getAttribute('data-title') != null && elem.getAttribute('data-title') != '') {
			shrTitle = elem.getAttribute('data-title');
		}	
	}	
    var shrLink = "//twitter.com/share?url=" + encodeURIComponent(shrUrl) + "&text=" + encodeURIComponent(shrTitle) + (window.location.href.indexOf('medscape.org') > -1 && window.location.pathname.indexOf('viewarticle') > -1 ? "&via=MedscapeCME" : "&via=medscape");
	
    wmdPageLink(trackVal, elem);
    socialCp('Twitter');	
    window.open(shrLink);
}
function googleurl(elem) {
	var trackVal = 'ar-share_google';
    var shrUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?src=soc_gp_share';
    var shrTitle = document.title;	
	if (typeof elem != 'undefined' && typeof elem.getAttribute != 'undefined') {	
		if (elem.getAttribute('data-track') != null && elem.getAttribute('data-track') != '') {
			trackVal = elem.getAttribute('data-track');
		}		
		if (elem.getAttribute('data-uri') != null && elem.getAttribute('data-uri') != '') {
			shrUrl = elem.getAttribute('data-uri') + '?src=soc_gp_share';
		}		
		if (elem.getAttribute('data-title') != null && elem.getAttribute('data-title') != '') {
			shrTitle = elem.getAttribute('data-title');
		}	
	}	
    var shrLink = "https://plusone.google.com/_/+1/confirm?url=" + encodeURIComponent(shrUrl) + "&title=" + encodeURIComponent(shrTitle);
	
    wmdPageLink(trackVal, elem);
    socialCp('Google+');	
    window.open(shrLink);
}
function linkedinurl(elem) {
	var trackVal = 'ar-share_link';
    var shrUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?src=soc_lk_share';
    var shrTitle = document.title;	
	if (typeof elem != 'undefined' && typeof elem.getAttribute != 'undefined') {	
		if (elem.getAttribute('data-track') != null && elem.getAttribute('data-track') != '') {
			trackVal = elem.getAttribute('data-track');
		}		
		if (elem.getAttribute('data-uri') != null && elem.getAttribute('data-uri') != '') {
			shrUrl = elem.getAttribute('data-uri') + '?src=soc_lk_share';
		}		
		if (elem.getAttribute('data-title') != null && elem.getAttribute('data-title') != '') {
			shrTitle = elem.getAttribute('data-title');
		}	
	}	
    var shrLink = "//www.linkedin.com/cws/share?url=" + encodeURIComponent(shrUrl) + "&title=" + shrTitle;
	
    wmdPageLink(trackVal, elem);
    socialCp('LinkedIn');
    window.open(shrLink);
}
function whatsappurl(elem) {
	var trackVal = 'ar-share_what';
    var shrUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    var shrTitle = document.title;	
	if (typeof elem != 'undefined' && typeof elem.getAttribute != 'undefined') {	
		if (elem.getAttribute('data-track') != null && elem.getAttribute('data-track') != '') {
			trackVal = elem.getAttribute('data-track');
		}		
		if (elem.getAttribute('data-uri') != null && elem.getAttribute('data-uri') != '') {
			shrUrl = elem.getAttribute('data-uri');
		}		
		if (elem.getAttribute('data-title') != null && elem.getAttribute('data-title') != '') {
			shrTitle = elem.getAttribute('data-title');
		}	
	}	
    var shrLink = "https://wa.me/send?l=en";
	if (locale=="de") shrLink = "https://wa.me/send?l=de"; 
	if (locale=="es") shrLink = "https://wa.me/send?l=es"; 
	if (locale=="fr") shrLink = "https://wa.me/send?l=fr";
	if (locale=="pt") shrLink = "https://wa.me/send?l=pt_br";
	shrLink = shrLink + '&text=' + shrUrl;
	
    wmdPageLink(trackVal, elem);
    socialCp('Whatsapp');
    window.open(shrLink);    
}

function viadeoeurl() {
    var title = document.title;
	var url = window.location.protocol + '//' + window.location.host + window.location.pathname + '?src=stviadeo';
    var viadeourl = "http://www.viadeo.com/shareit/share/?url=" + encodeURIComponent(url) + "&title=" + title;
    socialCp("Viadeo");
    window.open(viadeourl);
}
function socialCp(platform) {
    var cpSocData = new Object();
    cpSocData.appname = "social-functions";
    cpSocData.activityName = encodeURIComponent(platform);
    segVarPop(cpSocData);
    postCp(cpSocData);
}



function cpHlLoad() {
    if (cpHlArr.length > 0) {
        var cpHlData = new Object();
        cpHlData.appname = "alert";
        cpHlData.activityName = "headlineimpr";
        cpHlData.impr = cpHlArr;
        postCp(cpHlData);
    }
}

/* Communications Platform Native Ad - Non-iFramed */
function cpNativeImp(ntvAd) {
	if (typeof ntvAd == 'object') {
		var cpNtvObj = new Object();
		cpNtvObj.appname = "ad";
		cpNtvObj.activityName = "headlineimpr";
		cpNtvObj.impr = [];
		cpNtvObj.impr.push(ntvAd);
		postCp(cpNtvObj);
	}
}

var adRolloverPos;

function adRollover()
{
    $("script[src*='pos%3'],iframe[src*='pos%3']").each(function () {
        var currTagName = this.tagName;
        $(this).parent().bind('mouseover', function (e) {
            adRolloverPos = $(this).find(currTagName).filter(function () {
                return (this.src.match(/http:\/\/as\.medscape/) != null)
            })[0].src.match(/pos%3D\d+/)[0].substring(6);
        });
    });

    $("div[id*=ads-pos]").on('mouseover', function () {
        adRolloverPos = this.id.match(/\d+/)[0];
    });

}

function adExpand(Ver) {
    if (cpAdExpArr.length > 0) {
        var cpAdData = new Object();
        cpAdData.appname = "ad";
        cpAdData.activityName = "adimpr";
        cpAdData.impr = new Array();
        $(cpAdExpArr).each(function (index) {
            if (this.ver == Ver) {
                cpAdData.impr.push(this);
                cpAdData.impr[cpAdData.impr.length - 1].exp = 1;
            }
        });
        if (cpAdData.impr.length > 1 || cpAdData.impr.length == 0) {
            cpAdData.impr = new Array();
            $(cpAdExpArr).each(function (index) {
                if (this.pos == Number(adRolloverPos)) {
                    cpAdData.impr.push(this);
                    cpAdData.impr[cpAdData.impr.length - 1].exp = 1;
                }
            });
        }
        postCp(cpAdData);
    }
}

function cpAdLoad() {
    if (cpAdCallArr.length > 0) {
        var cpAdData = new Object();
        cpAdData.appname = "ad";
        cpAdData.activityName = "adimpr";
        cpAdData.impr = cpAdCallArr;
        postCp(cpAdData);
		cpAdExpArr = cpAdCallArr;
		cpAdCallArr = [];
    }
}

/* Shared CP Functions */
function segVarParam(value) {
    var segVarpar = new RegExp("(" + value + ")=([^; \n]*)");
    if ($('meta[name=metasegvar]').attr("content").match(segVarpar) != null) {
        var result = $('meta[name=metasegvar]').attr("content").match(segVarpar)[0].split("=")[1];
    }
    return result;
}

function cpDrvrImpr(idArr) {
    var cpDrvrObj = {'appname': 'ad', 'activityName': 'adimpr', 'impr': []};
    $(idArr).each(function () {
        var drvrImpId = new Date().getTime().toString().slice(2) + Math.random().toString().substr(2, 8);
        var drvrImpr = {'activityId': $.trim($('#' + this).attr('data')), 'pos': Number(this.match(/\d\d\d/)[0]), 'impId': drvrImpId, 'pvId': s_pageview_id};
        cpDrvrObj.impr.push(drvrImpr);
        $('#' + this + ' a').each(function () {
            if (typeof $(this).attr('href') != 'undefined')
            {
                if ($(this).attr('href').indexOf('?') == -1) {
                    $(this).attr('href', $(this).attr('href') + '?impId=' + drvrImpId + '&pvId=' + s_pageview_id);
                } else {
                    $(this).attr('href', $(this).attr('href') + '&impId=' + drvrImpId + '&pvId=' + s_pageview_id);
                }
            }
        });
    });
    postCp(cpDrvrObj);
}

/* Onload CP Functions */
window.onload = function () {
    cpHlLoad();
    adRollover();
};

function cpAdCheck() {	
	var dfpCompleted = false;
	var loadDebounce;
	$(document).on('dfpRenderComp', function() {
		dfpCompleted = true;
		$('div[id*=ads-pos] iframe').last().on('load', function () {
                clearTimeout(loadDebounce);
                loadDebounce = setTimeout(function () {
                    if (navigator.userAgent.toLowerCase().match(/(iphone)|(android)/) != null) {
                        setTimeout(function () {
                            if (cpAdCallArr.length > 0) {
                                cpAdLoad();
                            }
                        }, 1000);
                    } else {
                        if (cpAdCallArr.length > 0) {
                            cpAdLoad();
                        }
                    }
                }, 500);
		});
	});	
	setTimeout(function() {
		if (!dfpCompleted && cpAdCallArr.length > 0) {
                cpAdLoad();
           }
	}, 4500);
}

function emailCollection() {
    var links = window.document.getElementsByTagName("link");
    $(links).each(function () {
        if ($(this).attr("rel") == "canonical") {
            canonicalURL = $(this).attr("href");
        }
    });
    var articleID = canonicalURL.split("/");
    if (articleID[4].indexOf("_") > 0) {
        var a = articleID[4].split("_");
        articleID[4] = a[0];
    }
    adexGet('/px/viewcontenttools/web/emailthis/form.jsp?requestURL=' + canonicalURL + '&type=collection&cId=' + articleID[4] + '&subject=' + document.title, 'emailadexbox', 'adexwait', showemailbox);
}


var dataCopy;
var textCopy;
var typedInputNew = "";

var combolimit = 0;

var qrldrugpath;
var qrlpath;
var ILtimer;

var keyBlurFlg = false;

function closeil(elem) {
    if (!keyBlurFlg) {
        if (navigator.userAgent.toLowerCase().match(/msie 8/) != null) {
            if (this.value == '') {
                $(this).css('color', '#999');
                this.value = $(this).attr('placeholder');
            }
        }
        if (typeof elem.innerHTML != 'undefined') {
            setTimeout(function () {
                $(elem).find('.ilfulllist').hide();
            }, 300);
        }
        else {
            var self = this;
            setTimeout(function () {
                $(self).parents('form:eq(0)').find('.ilfulllist').hide();
            }, 300);
        }
    }
    keyBlurFlg = false;
}

function openil() {

    if (navigator.userAgent.toLowerCase().match(/msie 8/) != null) {
        if (this.value == $(this).attr('placeholder')) {
            $(this).css('color', '#000');
            this.value = '';
        }
        ;
    }

    //$(this).parents('form:eq(0)').find('.il_combo_nor').hide();
    if (this.value.length >= 3) {
        instantlook(this);
    }
    /* if ($(this).parents('form:eq(0)').find('ul.combolist').children().size() > 1) {
     $(this).parents('form:eq(0)').find('.il_combo_nor').hide();
     } else if ($(this).parents('form:eq(0)').find('ul.combolist').children().size() == 0 && this.value.length != 0) {
     $(this).parents('form:eq(0)').find('.il_combo_nor').show();
     } */
}

function ilArrowNav(event) {
    if (event.keyCode == 40 || event.keyCode == 9 && $(this).siblings('.ilfulllist').is(':visible')) {
        if (event.keyCode == 9) {
            keyBlurFlg = true;
        }
        else if (event.keyCode == 40) {
            event.preventDefault();
        }
        var par = $(this).parents('form:eq(0)');
        $(this).parents('form:eq(0)').find('.combolist li a').off('blur');
        $(this).parents('form:eq(0)').find('.combolist li a').on('blur', function (e) {
            setTimeout(function () {
                if ($(document.activeElement).parents('form:eq(0)').length < 1 || (typeof document.activeElement.type != 'undefined' && document.activeElement.type == 'submit') || $(document.activeElement).parents('form:eq(0)')[0] != par[0]) {
                    keyBlurFlg = false;
                    closeil(par[0]);
                }
            }, 10);
        });
        $(this).parents('form:eq(0)').find('.combolist li a').off('focus');
        $(this).parents('form:eq(0)').find('.combolist li a').on('focus', function () {
            $('.focused').removeClass('focused');
            $(this).parent().addClass('focused');
        });
        $(this).parents('form:eq(0)').find('.combolist li a').off('hover');
        $(this).parents('form:eq(0)').find('.combolist li a').on('hover', function () {
            $(this).focus();
        });
        $(this).parents('form:eq(0)').find('.combolist li a').off('keydown');
        $(this).parents('form:eq(0)').find('.combolist li a').on('keydown', function (e) {
            if (e.keyCode != 9) {
                e.preventDefault();
            }
            else if (e.keyCode == 9 && ($(this).parent().index() == $(this).parent().parent().children().last().index())) {
                e.preventDefault();
                $(this).parents('form:eq(0)').find('.search-input:eq(0)').focus();
            }
            if (e.keyCode == 40 || e.keyCode == 39) {
                if ($(this).parent().next().length > 0) {
                    $(this).parent().next().find('a').focus();
                }
            }
            else if ($(this).parent().index() == 0 && (e.keyCode == 38 || e.keyCode == 37)) {
                $('.focused').removeClass('focused');
                $(this).parents('form:eq(0)').find('.search-input:eq(0)').focus();
            }
            else if (e.keyCode == 38 || e.keyCode == 37) {
                $(this).parent().prev().find('a').focus();
            }
            else if (e.keyCode == 13 || e.keyCode == 32) {
                $(this).parents('form:eq(0)').find('.search-input:eq(0)').val($(this).html().replace(/<[^>]+>/g, ''));
                $(this).parents('form:eq(0)').find('.ilfulllist').hide();
                if (typeof $(this).attr('onclick') != 'undefined') {
                    eval($(this).attr('onclick'));
                }
                if (typeof $(this).attr('href') != 'undefined' && $(this).attr('href').indexOf('javascript') == -1) {
                    window.location.href = $(this).attr('href');
                }
            }

        });
        if (event.keyCode == 40) {
            keyBlurFlg = true;
            $(this).parents('form:eq(0)').find('.combolist li a:eq(0)').focus();
        }
    }
}

function il_call() {
    if (this.value.length >= 3) {
        instantlook(this);
    }
    else {
        $(this).parents('form:eq(0)').find('.ilfulllist').hide();
    }
}

function instantlook(context) {

    typedInputNew = context.value.replace("&", "\\&").replace("+", "\\+").replace("(", "\\(").replace(")", "\\)").replace("#", "\\#").replace("/", "\\/").replace("'", "\\'");

    if (typedInputNew.length == 0) {
        //$(context).parents('form:eq(0)').find('.il_combo_nor').hide();
        $(context).parents('form:eq(0)').find(".combolist")[0].innerHTML = "";
        combolimit = 0;

    } else if (context.value == textCopy) {
        showResults(dataCopy, context, context.value);

    } else {
        var text = context.value;
        textCopy = text;
        text = text.replace("&", "\\&").replace("+", "\\+").replace("(", "\\(").replace(")", "\\)").replace("#", "\\#").replace("/", "\\/").replace("'", "\\'");

        $.ajax({
            type: "GET",
            contentType: "application/jsonp",
            url: '//www.' + getDomain('profreg') + 'webmd.com/api/qrl/LookupService.ashx?q=' + text + '&sz=5&s=10001&namespace=medscape',
            dataType: "jsonp",
            jsonp: 'jsonp',
            timeout: 1000,
            success: function (data) {
                showResults(data, context, text);
            }
        });

    }

}

function showResults(data, context, query) {
    dataCopy = data;
    $(context).parents('form:eq(0)').find(".combolist")[0].innerHTML = "";
    if (typeof data != 'undefined' && typeof data.types != 'undefined' && data.types.length >= 1) {
        for (i = 0; i < data.types[0].references.length; i++) {
            var resultline = document.createElement("li");
            if (document.domain.match('search') != null) {
                if ($(context).parents('form:eq(0)').attr('id') == 'SearchFormHeader') {
                    var ilOmniPre = "hd-sr-srch_la";
                }
                else {
                    var ilOmniPre = "main-srch_la";
                }
            }
            else {
                var ilOmniPre = "hd-sr-srch_il";
            }
            if (document.domain.match('search') != null && window.location.href.match('&plr=') != null) {
                var plr = window.location.href.match(/&plr=[^&]+/)[0];
            }
            else if (typeof document.getElementById('searchdbvalue') != 'undefined' && document.getElementById('searchdbvalue').value == "3") {
                var plr = "&plr=edu";
            }
            else {
                var plr = "";
            }
            var queryRx = new RegExp("(" + query + ")", "ig");
            resultline.innerHTML = '<a href=\'//search.' + getDomain('profreg') + 'medscape.com/search?q=\"' + data.types[0].references[i].text + '\"' + plr + '\' onclick="wmdTrack(\'' + ilOmniPre + (i + 1) + '\')">' + data.types[0].references[i].text.replace(queryRx, '<span class="ta-hl">$1</span>') + '</a>';
            $(context).parents('form:eq(0)').find(".combolist")[0].appendChild(resultline);
            combolimit = combolimit + 1;
            // $(context).parents('form:eq(0)').find('.il_combo_nor').hide();
        }
    }
    if (combolimit > 0) {
        $(context).parents('form:eq(0)').find('.ilfulllist').show();
    }
    else {
        $(context).parents('form:eq(0)').find('.ilfulllist').hide();
    }
    /* if (combolimit < 1) {
     $(context).parents('form:eq(0)').find('.il_combo_nor').show();
     } */
    combolimit = 0;
}

function searchCp(site, callback, searchStr) {
	if (typeof callback == 'string') {
		searchStr = callback;
		callback = undefined;
	}
    var cpSrchData = new Object();
    cpSrchData.appname = "search";
    cpSrchData.activityName = site;
	if (typeof searchStr != 'undefined') {
		cpSrchData.search = searchStr;
	} else {
		cpSrchData.search = encodeURIComponent($('#search-input').val());
	}
    if ($('meta[name=metasegvar]').length > 0) {
        if (segVarParam("artid") != "0") {
            cpSrchData.activityId = segVarParam("artid");
        }
    }
    postCp(cpSrchData, callback);
}

function doSearch(locale, eduFlag) {
    localeString = ''
    if (locale != null && locale != 'en') {
        localeString = '/' + locale;
    }
    var _qval = '"' + encodeURIComponent($('#search-input').val()) + '"';
    if(typeof _qval == "undefined") {
        var _qval = '"' + encodeURIComponent($('#searchInput').val()) + '"';
    }
    if(_qval=="\"\"") {
        var _qval = encodeURIComponent($('#layer-search-input').val());
    }
	if (_qval.indexOf('@') != -1) { // remove any search query if email address might be present
		_qval = '';
	}

    if ($('#search-input').val() == "Search Medscape" || $('#searchInput').val() == "Search Medscape") {
        window.location.href = '//search.' + getDomain('profreg') + 'medscape.com/search' + localeString;
    }
    else {
        if (typeof eduFlag != 'undefined' && eduFlag == 'edu') {
            window.location.href = '//search.' + getDomain('profreg') + 'medscape.com/search' + localeString + '/?q=' + _qval + '&plr=edu';
        }
        else {
            window.location.href = 'http://search.' + getDomain('profreg') + 'medscape.com/search' + localeString + '/?q=' + _qval;
        }
    }
}

/* LEGACY SEARCH PILLAR SELECTOR DROPDOWN  - CAN BE REMOVED IF WE REMOVE FROM ALL REFERENCING JS FILES / EDIT FUNCTION BELOW */
function changeSeachDB(id, str) {
    if (document.getElementById(id)) {
        document.getElementById(id).innerHTML = str;
    }
}

function subsearchheadertrack(locale) {
    if (document.getElementById('searchdbvalue').value == "1") {
        wmdTrack('hd-sr-srch');
        searchCp("news", function () {
            doSearch(locale)
        });
    }
    if (document.getElementById('searchdbvalue').value == "2") {
        wmdTrack('hd-sr-srch');
        searchCp("reference", function () {
            doSearch(locale)
        });
    }
    if (document.getElementById('searchdbvalue').value == "3") {
        wmdTrack('hd-sr-srch');
        searchCp("education", function () {
            doSearch(locale, 'edu')
        });
    }
    if (document.getElementById('searchdbvalue').value == "4") {
        wmdTrack('hd-sr-srch');
        searchCp("medline", function () {
            doSearch(locale)
        });
    }
}


function runTooltip(cookieName, className, closeOnclick, allDomain) {
    if (document.cookie.indexOf(cookieName + '=') == -1) {
		$("body").addClass(className);
		if (typeof closeOnclick != 'undefined' && closeOnclick) {
			var optDomain = '';
			if (typeof allDomain != 'undefined' && (allDomain == 'all' || allDomain == 'com' || allDomain == true) && document.domain.indexOf('.com') != -1) {
				optDomain = '; domain=medscape.com';
			}
			cookieTooltip(cookieName, allDomain, optDomain);
			$(document).one("click", function() {
				$("body").removeClass(className);
			});
		}
	}
}

function cancelTooltip(cookieName, className, allDomain) {
	$("body").removeClass(className);
	var optDomain = '';
	if (typeof allDomain != 'undefined' && (allDomain == 'all' || allDomain == 'com' || allDomain == true) && document.domain.indexOf('.com') != -1) {
		optDomain = '; domain=medscape.com';
	}
	cookieTooltip(cookieName, allDomain, optDomain);
}

function cookieTooltip(cookieName, allDomain, optDomain) {
	document.cookie = cookieName+"='true'; path=/; expires=Tue, 01 Jan 2030 21:59:36 GMT" + optDomain;
	if (typeof allDomain != 'undefined' && allDomain == 'all' || allDomain == true) {
		var xCookieFrame = document.createElement('iframe');
		xCookieFrame.width = 0;
		xCookieFrame.height = 0;
		xCookieFrame.style.display = "none";
		document.body.appendChild(xCookieFrame);
		xCookieFrame.src = getXDom() + '/noscan/public/x-dom-cookie?cookieName=' + encodeURIComponent(cookieName);
	}
}

function getXDom() {
	var xD = "";
	if (document.domain.indexOf("staging.") != -1) {
		xD = "staging.";
	} else if (document.domain.indexOf("proddev.") != -1) {
		xD = "proddev.";
	}
	if (document.domain.match(/qa\d\d\./) != null) {
		xD = xD + document.domain.match(/qa\d\d\./)[0];
	}
	if (document.domain.match(/dev\d\d\./) != null) {
		xD = xD + document.domain.match(/dev\d\d\./)[0];
	}
	if (document.domain.indexOf('.com') != -1) {
		xD = '//www.' + xD + 'medscape.org';
	}
	else {
		xD = '//www.' + xD + 'medscape.com';
	}
	return xD;
}

$(document).ready(function() {
	if (toolTipArr.length > 1) {
		var priority = 1;
		$(toolTipArr).filter(function() {return (typeof this[4] == 'number' && this[4] > 0)}).sort(function(a,b) {return a[4] - b[4]}).each(function(idx) {
			if (idx == 0) {
				priority = this[4];
				runTooltip.apply(this, this);
			}
			else if (this[4] == priority) {
				runTooltip.apply(this, this);
			}
		});
	}
	else if (toolTipArr.length > 0) {
		runTooltip.apply(this, toolTipArr[0]);
	}
});


/* IFI Widget */
var ifiWidget = {
	'renderWidg': function (context) {
		if (typeof ifiWidgetData != 'undefined' && ifiWidgetData.length > 0) {
			var ifiLinkNum = 5;
			var ifiText = 'Information from Industry';
			var ifiTextMore = 'More';
			var currDom = '';
			if (typeof $(context).attr('data-ifi-link-ct') != 'undefined' && $(context).attr('data-ifi-link-ct') != '' && !isNaN($(context).attr('data-ifi-link-ct'))) {
				ifiLinkNum = Number($(context).attr('data-ifi-link-ct'));
			}
			if (typeof $(context).attr('data-ifi-translate') != 'undefined' && $(context).attr('data-ifi-translate') != '') {
				ifiText = $(context).attr('data-ifi-translate').split('|')[0];
				ifiTextMore = $(context).attr('data-ifi-translate').split('|')[1];
			}
			if (typeof getDomain != 'undefined') {
				currDom = getDomain();
			}
			$(context).replaceWith('<div class="ifi-widget"> <div class="ifi-widget-head"> <div class="ifi-widget-head-txt">' + ifiText + '</div>  </div> <div class="ifi-widget-cont"> <ul class="ifi-widget-cont-list"> </ul> </div> </div>');
			$(ifiWidgetData).each(function(idx) {
				if (idx+1 > ifiLinkNum) {
					return false;
				}
				$('.ifi-widget-cont-list').append('<li><a href="//wp.' + currDom + 'medscape.com/activity/viewpresentation?action=startActivity&activityId=' + this.activityId + '&tacticId=' + this.tacticId + '&src=ind_spt">' + this.randomTitle + '</a></li>');
			});
			$('.ifi-widget').addClass('active');
		}
		else if ($('#ifi-widget-card').parents('.column-card').length > 0) {
			$('#ifi-widget-card').parents('.column-card:eq(0)').hide();
		}
	},
	'init': function() {
		$('.trigger-ifi-widget').each(function() {
			ifiWidget.renderWidg(this);
		});
	}
}

var MarketViolator = {
    template: function (data) {
        return $('<section id="video-violator"><div class="resp-container">' +
            '<span class="text-contain"><span class="medscape-text">' + data.text.slot1 +
            '</span> <span class="video-text">' + data.text.slot2 +
            '</span> <span class="new-label">' + data.text.slot3 + '</span>' +
            '<span class="promo-copy"><span class="desktop-text">' + data.text.main.desktop + '</span><span class="mobile-text">' + data.text.main.mobile + '</span></span></span>&nbsp;' +
            '<a href="#" class="explore-now-button"><span class="desktop-text">' + data.text.button.desktop + '</span><span class="mobile-text">' + data.text.button.mobile + '</span></a>' +
            '<button onclick="wmdTrack(\'vv-close\')"  class="close-video-violator"><svg viewPort="0 0 14 14">' +
            ' <line x1="1" y1="11" x2="11" y2="1" stroke="#818181" stroke-width="2"/>' +
            ' <line x1="1" y1="1" x2="11" y2="11" stroke="#818181" stroke-width="2"/>' +
            '</svg></button></div></section>');
    },
    cookieName: '',
    init: function() {

        if (typeof s_user_origin === 'undefined' || (typeof isMscpApp !== 'undefined' && isMscpApp == true)) {
            return 0;
        }

        var url =  'https://img.' + getDomain() + 'medscapestatic.com/medscape-files/violator/violator.json';
        var self = this;

        $.get(url).done(function(data) {
		
		    self.cookieName = encodeURIComponent(data.url);
			
			if (typeof data.sspOvr != 'undefined' && typeof data.sspOvr.on != 'undefined' && data.sspOvr.on && typeof DFPTargetKeys != 'undefined' && DFPTargetKeys.pageSegVars.ssp != '0' && typeof data.sspOvr[DFPTargetKeys.pageSegVars.ssp] != 'undefined') {
				$.extend(true, data, data.sspOvr[DFPTargetKeys.pageSegVars.ssp]);
			}
            var matchURL = data.url.replace('.', '.' + getDomain());
            var targetURL = '//' + matchURL + (data.sourceMarker ? (matchURL.indexOf('?') === -1 ? '?src=' : '&src=') + data.sourceMarker : '');
            var expired = new Date() > Date.parse(data.expire);

            var $violator = self.template(data);
            var $button = $violator.find('.explore-now-button');
            var $close = $violator.find('.close-video-violator');

            var gated = true;
            switch (data.user) {
                case 'all': gated = true; break;
                case 'usmd': gated = typeof s_user_group !== 'undefined' && s_user_group.trim() === 'Physician' && typeof s_user_origin !== 'undefined' && s_user_origin.trim() === 'us'; break;
                case 'healthcareProvider': gated = qrllog === '1' && ['0', '16', '17', '19'].indexOf(DFPTargetKeys.userSegVars.pf) === -1; break;
                case 'onlyAnonymous': gated = qrllog === '0'; break;
                case 'onlyUser': gated = qrllog === '1'; break;
				case 'onlyPhysician': gated = qrllog === '1' && DFPTargetKeys.userSegVars.pf === '10'; break;
				case 'physicianOrStudent': gated = qrllog === '1' && ['10', '16'].indexOf(DFPTargetKeys.userSegVars.pf) !== -1; break;
                default: gated = true;
            }

			var showLocale = (typeof data.display.locale !== 'undefined' && typeof locale !== 'undefined' && $.trim(data.display.locale) == "" || data.display.locale == locale);
						
            var show =  data.display.always ||
                data.display.homepage.news && ($('#news').length !== 0 || $('#today').length !== 0 || typeof isNewsHomepage !== 'undefined' && isNewsHomepage ) && showLocale ||
                data.display.homepage.reference && $('#dd-hp').length !== 0 && showLocale ||
                data.display.homepage.cme && $('#edu').length !== 0 && showLocale ||
                data.display.content.news && (/\/viewarticle\//).test(location.pathname) && location.host.split('.').pop() === 'com' && showLocale ||
                data.display.content.reference && ['reference', 'emedicine'].indexOf(location.host.split('.')[0]) !== -1 && showLocale ||
                data.display.content.cme && document.domain.indexOf('medscape.org') !== -1 && showLocale;

            if (document.location.hostname + document.location.pathname === matchURL) {
                self.setCookie();
            }
            else if (!$.cookie(self.cookieName)  && !expired && show && gated) {

                $button.attr('href', targetURL);
                if (data.tracking) {
                    $button.click(function() {
                        wmdTrack(data.tracking);
                    });
                }

                $violator.addClass('show');
                $close.click(function() {
                    self.setCookie();
                    $violator.removeClass('show');
                });
                $button.click(function() {
                    self.setCookie();
                    $violator.removeClass('show');
                });

                $('body').append($violator);
            }
        })
    },
    setCookie: function() {
        $.cookie(this.cookieName, 'true', { expires: new Date(9999999999999), domain: document.domain, path: '/' }); // Random Date Way in Future
    },
    clearCookie: function() {
        var expiration = new Date('Thu, 01 Jan 1970 00:00:00 GMT');
        $.cookie(this.cookieName, '', { expires: expiration, domain: document.domain, path: '/' })
    }
}

 $(document).ready(function () {

     if (document.getElementById('ot-stub') == null || document.cookie.match(/OptanonConsent[^;]+&groups[^;]+C0004%3A1/) != null || (document.getElementById('ot-ccpa') != null && document.cookie.match(/OptanonConsent[^;]+&groups/) == null)) {
         /* TEMPORARY CODE - TRACK ANONYMOUS USERS VIA COOKIE */
         if ($.cookie('persistanon') == null && document.domain.match('medscape.com') != null) {
             $.cookie('persistanon', 'a' + new Date().getTime() + Math.floor(Math.random() * 10001), {
                 expires: 365,
                 domain: 'medscape.com'
             });
         }
         /* /TEMPORARY CODE - TRACK ANONYMOUS USERS VIA COOKIE */


         /* 'Accordant' vendor pixel tracking code */
         if (document.domain.match('medscape.com') != null) {
             if (typeof s_registered_user_id != 'undefined') {
                 pixTrack('//ib.adnxs.com/px?id=487729&seg=2369208&order_id=' + s_registered_user_id + '&t=1');
                 pixTrack('//u.acuityplatform.com/us?tpId=42&tpuid=' + s_registered_user_id);
             } else if ($.cookie('persistanon') != null) {
                 pixTrack('//ib.adnxs.com/px?id=487729&seg=2369208&order_id=' + $.cookie('persistanon') + '&t=1');
                 pixTrack('//u.acuityplatform.com/us?tpId=42&tpuid=' + $.cookie('persistanon'));
             }
         }
         /* /'Accordant' vendor pixel tracking code */
     }

    cpAdCheck();

    if ('ontouchstart' in document.documentElement) {
        $('body').addClass('touchscreen');
    }

    $("#mobile-nav-checkbox").on('click', function(){
        if ( $(this).is(':checked') ) {
            $('body').addClass("menuView");
            // When the menu opens the Invites layer (if displayed) should hide
            $("#hidden-invite-layer").removeClass('show-invites');
            $("#hidden-invite-layer").addClass('hide-invites');
            $('body').removeClass('lock-touchscreen');
        }
        else {
            $('body').removeClass("menuView");
        }
    });

    $('.user-links-settings, .user-popup').bind('click', function() {
        $('.user-popup-menu').toggleClass('is-expanded');
      //  $('.user-popup-menu').addClass('is-expanded');
    });
    $('.user-links-settings, .user-popup').bind('mouseover', function() {
        // $('.user-popup-menu').removeClass('is-expanded');
        $('.user-popup-menu').addClass('is-expanded');
    });
    $('.user-links-settings, .user-popup').bind('mouseout', function() {
        $('.user-popup-menu').removeClass('is-expanded');
        // $('.user-popup-menu').addClass('is-expanded');
    });
	
	ifiWidget.init();

    MarketViolator.init();

	// PPE-214184 - searches from .org articles should go to CME section of results
	if (typeof document.getElementById('searchdbvalue') != 'undefined' && document.getElementById('searchdbvalue') != null && location.href.indexOf('medscape.org') > -1) {
		document.getElementById('searchdbvalue').value = "3";
	}

     //backlink code called here
     backlink();

 });

//code to remove wb.modimp page state tracking for fpf/faf when virtual pages and other pages are called in an artcile
$(document).on("click touchend", ".back_btn , .next_btn, .page-no, #previous-section, #next-section", function () {
   wmdRemContext('wb.modimp');
});

//
// UAC Creation Code
//
$(document).ready(function () { 
    if (typeof (s_registered_user_id) != 'undefined') {
        setTimeout(function () {
            var egg = s_registered_user_id;
            var gg = (egg / 27);
            var sgg = gg.toString();
            var tsll = sgg.charAt(sgg.length - 2);
            var tll = sgg.charAt(sgg.length - 1);
            medfuac = sgg.substring(0, sgg.length - 2);
            for (xii = 0; xii < gsll.length; xii++) {
                if (tsll == gsll[xii][0]) {
                    medfuac = medfuac + gsll[xii][1];
                }
            }
            for (xii = 0; xii < gll.length; xii++) {
                if (tll == gll[xii][0]) {
                    medfuac = medfuac + gll[xii][1];
                }
            }
        }, 750);
    }
    ;

//Code for replacing thumbnail placeholders with img tags
    $('.featimgVar, .confsection img').each(function (i, obj) {
		if (typeof $(this).attr('data-src') != 'undefined') {
			var dataThumbnailQuery = $(this).closest('ul').attr('data-thumbnail-query');
			if (dataThumbnailQuery === undefined || dataThumbnailQuery === null) {
				var tempSrc = $(this).attr('data-src');
				var tempAlt = $(this).attr('data-alt');
				$('<img src="' + tempSrc + '?interpolation=lanczos-none&resize=300:*" alt="' + tempAlt + '" border="0" class="featimg" />').insertAfter($(this));
			}
			else {
				var tempSrc = $(this).attr('data-src');
				var tempAlt = $(this).attr('data-alt');
				$('<img src="' + tempSrc + '?' + dataThumbnailQuery + '"alt="' + tempAlt + '" border="0" class="featimg" />').insertAfter($(this));
			}
			$(this).remove();
		} else if (this.src.match(/\.com\/\w+\/pi\/global\/1x1.png/) != null) {
			this.src = this.src.replace(/\.com\/\w+\/pi\/global\/1x1.png/,'.com/pi/global/1x1.png');
		}
    });

 }); 

/* Functions For Search Type-Ahead */
 $(document).ready(function () { 
    if (typeof $('.search-input, #layer-search-input').on != 'undefined') {
        $('.search-input, #layer-search-input').on('focus', openil);
        $('.search-input, #layer-search-input').on('keyup', il_call);
        $('.search-input, #layer-search-input').on('keydown', ilArrowNav);
        $('.search-input, #layer-search-input').on('blur', closeil);

        if (navigator.userAgent.toLowerCase().match(/msie 8/) != null) {
            $('.search-input, #layer-search-input').each(function () {
                $(this).css('color', '#999');
                this.value = $(this).attr('placeholder');
            });
        }
    }
 }); 

 
/* FROM MEDSCAPE-LIBRARY */


/* FROM MEDSCAPE-CORE */

// function that parses page and applies expand power to any item with
// a class of ".js-expand-button" and a data-attribute of "data-section"
function expandSections() {
    $('.js-expand-button').on('click', function(event){
        event.preventDefault();
        var theSectionString = $(this).data('section');
        $(theSectionString).first().toggleClass('is-expanded');
		$(this).find('.sp-arrow').toggleClass('is-rotated');	
		
		if ($(this).hasClass('mobile-edition-toggle')) {
			if ($(theSectionString).first().hasClass('is-expanded')) {
				wmdPageLink('hd-gl_langopen');
			} else {
				wmdPageLink('hd-gl_langclose');
			}
		}
    });
}
function closeDropdown() {
    $('.js-close-dropdown').on('click', function(event){
        event.preventDefault();
        $('.header-specialty-menu').removeClass('is-expanded');
		$('.header-specialty-toggle').find('.sp-arrow').removeClass('is-rotated');
    });
}

var regionIpChk = {
    msgMap: {
		'us': 'This site is intended for healthcare professionals',
		'de': 'Diese Seite richtet sich an Fachkreise (Angehörige der Heilberufe)',
		'fr': 'Ce site est destiné aux professionnels de santé',
		'es': 'Este sitio web está dirigido exclusivamente a los profesionales de la salud',
		'pt': 'Este site é dirigido exclusivamente a profissionais de saúde'
	},
    checkAjax: function() {
            $.support.cors = true;
            $.ajax({
                type: "GET",
                url: "/api/visitorcountry/visitorcountry.svc/getvisitorcontinent",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
				success: function (response) {
					regionIpChk.checkRegion(response);
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    // alert(XMLHttpRequest.response);
                    // alert(textStatus);
                    var error = eval('(' + XMLHttpRequest.response + ')');
                    //  alert("Error occured while calling Visitor Country!");
                }
            });
    },
    checkRegion: function(response) {
		if (response.toString().toLowerCase() != "us") {
			$('body').addClass('show-hcp-message');
			$('body').prepend('<div class="hcp-notify" style="font-family: \'proxima_nova_rgregular\', sans-serif; font-size: .625rem; padding: 3px; text-align: center;">' + regionIpChk.msgMap[locale] + '</div>');
		}	
    }	
}

 $(document).ready(function() { 
	if ($('script[src*="medscape-core.js"]').length == 0) { // CAN CHANGE THIS LOGIC POST HEADER-LAUNCH TO if (typeof s_responsive_design != 'undefined' && s_responsive_design)
		// call expandable sections function
		expandSections();
		closeDropdown();
		regionIpChk.checkAjax();
	}
});


/* FROM MEDSCAPE-CORE */

//BACKLINK CODE

//get query param by name
function getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

//get cookie by name
function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

//click on backlink function
function backClick(val, track) {
    event.preventDefault();
    wmdPageLink(track);
    $('#affiliate-backlink').hide( "slow" );
    if (document.cookie.indexOf(val) !== -1) {
        var newUrl = decodeURIComponent(getCookie(val));
    }
    else if (document.cookie.indexOf(val) !== -1) {
        var newUrl = decodeURIComponent(getCookie(val));
    }
    var expiration = new Date('Thu, 01 Jan 1970 00:00:00 GMT');
    $.cookie(val, document.referrer, { expires: expiration, domain: '.medscape.com', path: '/' });
    if (typeof newUrl !== 'undefined') {
        window.location.href = newUrl;
    }
    else {
        window.location.href = decodeURIComponent(document.referrer);
    }
}

//backlink function
function backlink() {
    //create backlink if backlink ecd query param or the backlink cookie exists
    var ecd = getParameterByName("ecd", window.location.href);
    if (document.referrer.indexOf("powerpak.com") !== -1 && ecd === "par_powerpak_drug") {
        var bLinkVars = {
            affiliate: 'powerpak',
            cookieVal: 'powerpak-backlink',
            trackingVal: 'ar-affil_ppakcls'
        }
		var expiration = new Date('Thu, 01 Jan 1970 00:00:00 GMT');
        $.cookie('uk-backlink', document.referrer, {expires: expiration, domain: '.medscape.com', path: '/'});
    }
	else if (document.referrer.indexOf("medscape.co.uk") !== -1 && ecd === "par_mscpuk") {
        var bLinkVars = {
            affiliate: 'uk',
            cookieVal: 'uk-backlink',
            trackingVal: 'ar-affil_uukcls'
        }
		var expiration = new Date('Thu, 01 Jan 1970 00:00:00 GMT');
        $.cookie('powerpak-backlink', document.referrer, {expires: expiration, domain: '.medscape.com', path: '/'});
    }
    else if (document.cookie.indexOf("powerpak-backlink") !== -1) {
        var bLinkVars = {
            affiliate: 'powerpak',
            cookieVal: 'powerpak-backlink',
            trackingVal: 'ar-affil_ppakcls'
        }
    }
	else if (document.cookie.indexOf("uk-backlink") !== -1) {
        var bLinkVars = {
            affiliate: 'uk',
            cookieVal: 'uk-backlink',
            trackingVal: 'ar-affil_uukcls'
        }
    }
    if (typeof bLinkVars !== 'undefined') {
        var backlink = document.createElement('div');
        backlink.setAttribute('id', 'affiliate-backlink');
        if (bLinkVars.affiliate === 'uk') {
            if (window.breakpoint === 1) {
                backlink.innerHTML = '<a onclick="backClick(\'uk-backlink\', \'ar-affil_uuk\')">\n' +
                    '\t\t<div class="close-backlink"></div>' +
                    '     \t\t<span class="icon-misc-arrow-down">\n' +
                    '\t\t</span>\n' +
                    '     \t\t<span class="back-to uk-back-to">\n' +
                    '\t\t\tBack to\n' +
                    '\t\t</span>\n' +
                    '\t\t<span class="logo-medscape-uk-univadis">\n' +
                    '\t\t\tMedscape UK\n' +
                    '\t\t</span>\n' +
                    '\t\t</a>';
            } else {
                backlink.innerHTML = '<a onclick="backClick(\'uk-backlink\', \'ar-affil_uuk\')">\n' +
                    '\t\t<div class="close-backlink"></div>' +
                    '\t\t<span class="icon-misc-arrow-down"></span>\n' +
                    '\t\t<span class="back-to uk-back-to">Back to</span>\n' +
                    '\t\t<img src="//img.' + window.getDomain() + 'medscapestatic.com/pi/logos/medscape-uk/logo-medscape-uk.svg" class="logo-medscape-uk-univadis"\n' +
                    '\t</div>\n' +
                    '\t</a>';
            }
        } else {
            backlink.innerHTML = '<a onclick="backClick(\'powerpak-backlink\', \'ar-affil_ppak\')">\n' +
                '\t\t<div class="close-backlink"></div>' +
                '\t\t<span class="icon-misc-arrow-down"></span>\n' +
                '\t\t<span class="back-to powerpak-back-to">Back to</span>\n' +
                '\t\t<img src="//img.' + window.getDomain() + 'medscapestatic.com//pi/logos/powerpak/PPKLogoBlue.svg" class="logo-powerpak"\n' +
                '\t</div>\n' +
                '\t</a>';
        }
        $(backlink).insertBefore($("#headerbox"));
        $('#affiliate-backlink').show();

        $('.close-backlink').bind('click', function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            $('#affiliate-backlink').hide("slow");
            wmdPageLink(bLinkVars.trackingVal);

            var expiration = new Date('Thu, 01 Jan 1970 00:00:00 GMT');
            $.cookie(bLinkVars.cookieVal, document.referrer, {expires: expiration, domain: '.medscape.com', path: '/'})
        });
        //if there is no ecd query param, see if a referrer is stored in the backlink param
        if (document.cookie.indexOf(bLinkVars.cookieVal) !== -1 && document.referrer.indexOf("powerpak.com") === -1 && document.referrer.indexOf("medscape.co.uk") === -1) {
            //set backlink
            $('#affiliate-backlink a').attr("href", decodeURIComponent(getCookie(bLinkVars.cookieVal)));
        }
        //check if backlink ecd param exists and if so, display backlink and store referrer
        else if (ecd === "par_powerpak_drug" && document.referrer.indexOf("powerpak.com") !== -1) {
            //add backlink cookie to store referrer value
            $.cookie(bLinkVars.cookieVal, document.referrer, {
                domain: '.medscape.com',
                path: '/'
            })
            //set backlink
            $('#affiliate-backlink a').attr("href", decodeURIComponent(document.referrer));
        } else if (ecd === "par_mscpuk") {
            //add backlink cookie to store referrer value
            if (document.referrer.indexOf("medscape.co.uk") !== -1) {
                $.cookie(bLinkVars.cookieVal, document.referrer, {
                    domain: '.medscape.com',
                    path: '/'
                })
                //set backlink
                $('#affiliate-backlink a').attr("href", decodeURIComponent(document.referrer));
            }
            //we lost uk referrer - login wall case. Store uk homepage as referrer val
            else {
                $.cookie(bLinkVars.cookieVal, "https://www." + window.getDomain() + "medscape.co/uk", {
                    domain: '.medscape.com',
                    path: '/'
                })
                //set backlink
                $('#affiliate-backlink a').attr("href", decodeURIComponent("https://www." + window.getDomain() + "medscape.co/uk"));
            }
        }
    }
}

//Viafoura comment count logic
var sectionUuidObj = {};
var commentCountAlwaysShowList = [];

function vfCommentCountAppend(responseArr) {
    responseArr.forEach(function(obj) {
        if (obj.commentCount > 0) {	
            if(commentCountAlwaysShowList.indexOf(obj.containerId) >=0 ) {
                commentCountAlwaysShowList.splice(commentCountAlwaysShowList.indexOf(obj.containerId),1);
            } 
			$('.viafoura-commentcount[data-vf-aspenarticle="' + obj.containerId + '"]').each(function() {
				this.innerHTML = obj.commentCount;
				$(this).parent().addClass("hasComments");
			});
            $('.viafoura-commentcount[data-vf-article-id="' + obj.containerId + '"]').each(function() {
				this.innerHTML = obj.commentCount;
				$(this).parent().addClass("hasComments");
			});
        }
    });
    
    commentCountAlwaysShowList.forEach(function(aspnId){
        $('.viafoura-commentcount[data-vf-aspenarticle="' + aspnId + '"]').each(function() {
            this.innerHTML = 0;
            $(this).parent().addClass("hasComments");
        });
    });

    commentCountAlwaysShowList.forEach(function(articleId){
        $('.viafoura-commentcount[data-vf-article-id="' + articleId + '"]').each(function() {
            this.innerHTML = 0;
            $(this).parent().addClass("hasComments");
        });
    });

	document.dispatchEvent(new CustomEvent('vfCommentCount'));
}

function getVfCommentCount() {
    Object.keys(sectionUuidObj).forEach(function(sectionUuid) {
        $.ajax({
            type: "POST",
            contentType: "application/json",
            url: "https://livecomments.viafoura.co/v4/livecomments/" + sectionUuid + "/content-containers/comment-count-and-status/by-container-ids",
            dataType: "json",
            data: JSON.stringify(sectionUuidObj[sectionUuid]),
            success: vfCommentCountAppend
        });
    });
}

function vfCommentCount() {
    //get all vf commentcount spans
    var commentCountTags = document.getElementsByClassName("viafoura-commentcount");

    $(commentCountTags).each(function() {
        var sectionUuid = this.getAttribute("data-vf-section-uuid");
        if (typeof sectionUuidObj[sectionUuid] == 'undefined') {
            sectionUuidObj[sectionUuid] = [];
        }
		var thisArtId = this.getAttribute("data-vf-article-id");
		if (this.getAttribute("data-vf-aspenarticle") != null && this.getAttribute("data-vf-aspenarticle") != '') {
			thisArtId = this.getAttribute("data-vf-aspenarticle");
		}
		if (thisArtId != null && thisArtId != '' && sectionUuidObj[sectionUuid].indexOf(thisArtId) == -1) { // no need to push dupes
			sectionUuidObj[sectionUuid].push(thisArtId);		
		}        
        if (this.getAttribute("data-vf-alwaysshow") === 'true' && commentCountAlwaysShowList.indexOf(thisArtId) == -1) {
            commentCountAlwaysShowList.push(thisArtId);
        }
    });

    getVfCommentCount();
}

/* LANGUAGE EDITIONS DROPDOWN */
$(document).ready(function() {
	
	// Add extra mappings to univadisMap object for more links to appear in menu
	var univadisMap = {
		'fr': {
			'leaveMsg': 'Vous allez maintenant quitter Medscape pour consulter plus d\'actualités médicales françaises sur notre site partenaire Univadis.',
			'loginMsg': 'Vous souhaitez lire davantage d\'actualités médicales en français ? Découvrez l\'édition française de notre site partenaire Univadis.',
			'lang': 'Français',
			'langEn': 'French',
			'contBtnMsg': 'Continuer sur Univadis',
			'retBtnMsg': 'Revenir sur Medscape',
			'isNew': true,
            'loginEnable': false
		},
		'it': {
			'leaveMsg': 'Stai uscendo da Medscape per visualizzare le ultime notizie mediche italiane dal nostro sito partner, Univadis.',
			'loginMsg': 'Sei interessato a leggere le ultime notizie mediche italiane? Scopri la nostra edizione italiana sul nostro sito partner, Univadis.',
			'lang': 'Italiano',
			'langEn': 'Italian',
			'contBtnMsg': 'Continua verso Univadis',
			'retBtnMsg': 'Ritorna a Medscape',
			'isNew': true,
            'loginEnable': true
		}
	}
	
	var currCtry = null;	
	var langMenuOpen = false;
	var hoverDebounce = null;
	var loginRegFlag = false;
	var loggedInSess = ((typeof s_auth_channel_id == 'string' && s_auth_channel_id.indexOf('104') == 0) || (typeof PageMetadata != 'undefined' && typeof PageMetadata.authVar != 'undefined' && typeof PageMetadata.authVar.authenticationChannel != 'undefined' && PageMetadata.authVar.authenticationChannel.toString() == '104'));
	var userCtryApplies = ((typeof s_user_origin == 'string' && typeof univadisMap[s_user_origin.trim()] != 'undefined') || (typeof PageMetadata != 'undefined' && typeof PageMetadata.userSegVars != 'undefined' && typeof PageMetadata.userSegVars.ct != 'undefined' && typeof univadisMap[PageMetadata.userSegVars.ct.trim()] != 'undefined'));
	
	// Menu open/close functions
	function closeLangMenu() {		
		langMenuOpen = false;
		$('.header-edition').removeClass('active');
		$(document).off('touchstart', menuClickOut);
		wmdPageLink('hd-gl_langclose', $('.header-edition-link')[0]);		
	}
	
	function openLangMenu() {		
		langMenuOpen = true;
		$('.header-edition').addClass('active');
		$(document).on('touchstart', menuClickOut);
		wmdPageLink('hd-gl_langopen', $('.header-edition-link')[0]);	
	}
	
	function handleHover(e) {
		var insideMenu = true;
		if (e.type == 'mouseleave') {
			insideMenu = ($(e.relatedTarget).hasClass('header-edition') || $(e.relatedTarget).parents('.header-edition').length > 0);
		}
		clearTimeout(hoverDebounce);
		hoverDebounce = setTimeout(function () {
			if (insideMenu && !langMenuOpen) {
				openLangMenu();
			} else if (!insideMenu && langMenuOpen) {
				closeLangMenu();
			}
		}, 125);
	}
	
	function menuClickOut(e) {
		if (!$(e.target).hasClass('header-edition') && $(e.target).parents('.header-edition').length == 0 && langMenuOpen) {
			$(document).one('touchend', function(e) {
				e.preventDefault();
				e.stopPropagation();
			});
			closeLangMenu();
		}
	}
	
	// Drop cookie for login/reg layer display
	function layerCookie() {
		if (loginRegFlag && document.cookie.indexOf('uni-msg-shown') == -1) {
			loginRegFlag = false;
			var cookExpDate = new Date();
			cookExpDate.setDate(cookExpDate.getDate() + 30);
			cookExpDate = cookExpDate.toUTCString();
			document.cookie = 'uni-msg-shown=true; path=/; domain=medscape.com; expires=' + cookExpDate;
		}
	}

	// Bind menu open/close for click and hover
	$('.header-edition-link').on('click', function() {
		if ($(this).parent().hasClass('active')) {
			closeLangMenu();
		} else {
			openLangMenu();
		}
	});
    $('.header-edition, .header-edition-menu').hover(handleHover);
	
	// Dynamically Univadis links
	Object.keys(univadisMap).forEach(function(key) {
		var isNewLabel = '';
		if (univadisMap[key].isNew) {
			isNewLabel = ' <span class="label-new">New</span>';
		}
		$('.editions-univadis .editions-list').append('<li class="' + key + '"><a class="external" href="javascript:void(0);" onclick="wmdPageLink(\'hd-gl_u' + key + '\', this);">' + univadisMap[key].lang + isNewLabel + '</a></li>'); // Desktop
		$('.mobile-menu-item-wrap.univadis').append('<div class="mobile-menu-item"><a class="' + key + ' external" href="javascript:void(0);" onclick="wmdPageLink(\'hd-gl_u' + key + '\', this);">' + univadisMap[key].lang + isNewLabel + '</a></div>'); // Mobile
	});

	// Univadis link bindings. Populate layer text for selected language, then display exit layer
	$('.editions-univadis .editions-list li, .mobile-menu-item-wrap.univadis .mobile-menu-item a').on('click', function() {
		currCtry = this.className.replace(' external','');
		$('.leaving-edition .description.lang').html(univadisMap[currCtry].leaveMsg);
		$('.leaving-edition .description.en').html('You are now leaving Medscape to view the latest ' + univadisMap[currCtry].langEn + ' medical news from our partner site, Univadis.');
		$('.leaving-edition .continue').html(univadisMap[currCtry].contBtnMsg);
		$('.leaving-edition .return').html(univadisMap[currCtry].retBtnMsg);
		$('.leaving-edition').addClass('active');
		$('.greyout-layer').addClass('active');
	});
	
	// Exit layer continue/cancel click bindings
	$('.leaving-edition .continue').on('click', function() {
		layerCookie();
		wmdPageLink('le-uni' + currCtry + '_cont', this);
		var domain = '';
		if (getDomain().indexOf('dev') != -1) {
			domain = 'devint.';
		} else if (getDomain().indexOf('qa') != -1) {
			domain = 'qa.';
		} else if (getDomain().indexOf('staging') != -1) {
			domain = 'staging.';
		}
		window.location.href = 'https://www.' + domain + 'univadis.' + currCtry;
	});

	$('.leaving-edition .return, .leaving-edition .close-btn').on('click', function() {
		layerCookie();
		if ($(this).hasClass('close-btn')) {
			wmdPageLink('le-uni' + currCtry + '_close', this);
		} else {
			wmdPageLink('le-uni' + currCtry + '_medsc', this);
		}
		$('.greyout-layer').removeClass('active');
		$('.leaving-edition').removeClass('active');
	});
	
	// Login layer display
	if ((loggedInSess || location.search.indexOf('reg=1') != -1) && userCtryApplies && document.cookie.indexOf('uni-msg-shown') == -1 && document.domain.indexOf('medscape.org') == -1) {
		if (typeof s_user_origin == 'string') {
			currCtry = s_user_origin.trim();
		} else {
			currCtry = PageMetadata.userSegVars.ct.trim();
		}
        if(univadisMap[currCtry].loginEnable) {
            loginRegFlag = true;
            $('.leaving-edition .description.lang').html(univadisMap[currCtry].loginMsg);
            $('.leaving-edition .continue').html(univadisMap[currCtry].contBtnMsg);
            $('.leaving-edition .return').html(univadisMap[currCtry].retBtnMsg);
            $('.leaving-edition').addClass('active');
            $('.greyout-layer').addClass('active');
            wmdPageLink('le-uni' + currCtry + '_loglayer');
        }
	}
	
});
/* / LANGUAGE EDITIONS DROPDOWN */

function load1523() {
	
    var isPageOne = (window.location.pathname.match(/viewarticle\/\d+_/) == null || window.location.pathname.match(/viewarticle\/\d+_1/) != null);
	
	var selPrecedence = ['[id*="ads-pos-1122-a"]','.decision-point [id*="ads-pos-1122"]','.clinical-review [id*="ads-pos-1122"]', '.clinical-forum [id*="ads-pos-1122"]','.algorithm [id*="ads-pos-1122"]','.expert-guidance [id*="ads-pos-1122"]','.mdangle [id*="ads-pos-1122"]','.fast-five-quiz .qna-render','.skill-check-up .qna-render','.care-cues [id*="ads-pos-1909"]','.coe section','.cc .hp_video-feature','#videoContent ~ .paywall [id*="ads-pos-1122"]','.article-content-wrapper .qna-render','.article-content-wrapper .txtAd1520SpcHolder','useParentElem|#profVideoArticlesContainer ~ div [id*="ads-pos-1122"]','useParentElem|.article__main-content .qna-render','useParentElem|.article__main-content #ads-pos-1520','.coe-v2 >section:last-of-type'];
	
    if (isPageOne) {
		for (var i=0; i < selPrecedence.length; i++) {			
			var useParentElem = false;
			if (selPrecedence[i].indexOf('|') != -1) {
				selPrecedence[i] = selPrecedence[i].split('|')[1];
				useParentElem = true;
			}			
			var targetElem = document.querySelector(selPrecedence[i]);
			var targetId = 'ads-pos-1523';
			if (typeof mscpA2A == 'boolean' && mscpA2A && document.querySelector('.inf-art-wrap') != null) {
				if (document.querySelector('.inf-art-wrap[data-contenturl="' + currSlctCont + '"] [id*="ads-pos-1523"]') != null) {
					return;
				}
				targetId = 'ads-pos-1523-' + window.location.pathname.match(/[^/-]+$/)[0];
				if (document.querySelector('.inf-art-wrap[data-contenturl="' + currSlctCont + '"]' + selPrecedence[i]) != null) {
					targetElem = document.querySelector('.inf-art-wrap[data-contenturl="' + currSlctCont + '"]' + selPrecedence[i]);
				} else if (document.querySelector('.inf-art-wrap[data-contenturl="' + currSlctCont + '"] ' + selPrecedence[i]) != null) {
					targetElem = document.querySelector('.inf-art-wrap[data-contenturl="' + currSlctCont + '"] ' + selPrecedence[i]);
				} else {
					targetElem = null;
				}
			}
			if (targetElem != null) {
				if (useParentElem) {
					targetElem = targetElem.parentNode;
				}
				targetElem.insertAdjacentHTML('beforebegin', '<div id="' + targetId + '" class="AdUnit"></div>');
				webmd.ads2.defineAd({
					id: targetId,
					pos: '1523',
					collapseAfter: true,
					immediate: true
				});
				break;	
			}
		}
    }
	
}
window.addEventListener('DOMContentLoaded', load1523);

/* TEMPORARY EDGE CACHE, remove when backend replaced - PPE-446436 
if (document.getElementById('whiteoutlayer') != null) {
	document.getElementById('whiteoutlayer').removeAttribute('style');
}
if (typeof isFPFEligible != 'undefined' && fpfMeta['isFPF'] == true) {
	$('body').append('<input type="hidden" id="fpfAdsSuppress" value="141,420,520,1122,1420,1520"></input>');
}
*/ 